import React from 'react';

function Reflection() {
  return (
    <section id="reflection">
      <h2 id='reflectionHeader'>Reflection</h2>
      <p id='reflectionP'>
      Reflecting on my time as a co-designer, 
      I realize that the apprenticeship was more than a learning curve; 
      it has been a voyage of discovery, learning, and joy.
      The skills acquired, the creativity unleashed, and the connections made have equipped me for the future, 
      ensuring that I carry forward not just memories but a foundation for lifelong learning and professional development.
      Throughout the apprenticeship, the company's value of working with wonder and embracing the ongoing journey of growth has been a guiding light, 
      helping me find the joy in learning. This ethos has not only made me a better professional but also a more fulfilled person, 
      forever in pursuit of growth and excellence.
      </p>
    </section>
  );
}

export default Reflection;
