import React from 'react';
import projectImage1 from '../images/Bottle.png';
import projectImage2 from '../images/Proj2.png';
import projectImage3 from '../images/Proj3.png';

function Projects() {

  return (
    <section id="projects">
      <div className="project-card">
        <img src={projectImage1} alt="Project 1" />
        <h3>2023 Summer Final Celebration Merchandise Design</h3>
        <p>
            As the Summer 2023 internship was coming to a close, we needed merch for the celebration event. 
            As a team we came up with many concepts, and ideas. Once we were set on what we wanted, 
            I stepped forward to come up with water bottle designs. I used Fusion360 to model the waterbottle formfactors and
            then designed the graphics in Adobe Illustrator.
        </p>
        <h3>Strengths Incorporated</h3>
        <p>
          <ul>
            <li><b>Developer - Relationship Building</b></li>
          </ul>
        </p>
      </div>

      <div className="project-card">
        <img src={projectImage2} alt="Project 2" />
        <h3>Staff Merch & Internal Merch Ordering Tool</h3>
        <p>
          As Merchandise was being planned for WorkWonder Con, 
          we settled on having a collection of 42 Hoodies/T-Shirts be exclusive to Staff. 
          All of the featured designs were made start-to-finish in Adobe Illustrator, 
          while others started in Adobe Photoshop or Procreate and finalized in Adobe Illustrator. 
          To keep record of what staff wanted, 
          I made an Airtable tool that served as a request form for any Merchandise combination possible.
        </p>
        <h3>Strengths Incorporated</h3>
        <p>
          <ul>
            <li><b>Developer - Relationship Building</b></li>
            <li><b>Restortative - Executing</b></li>
          </ul>
        </p>
      </div>

      <div className="project-card">
        <img src={projectImage3} alt="Project 3"/>
        <h3>Early WWC Website Wireframing</h3>
        <p>
          Spearheaded a lot of early WWC website wireframing and development. 
          The process began in Mural mapping out what needs to be shown and displayed and then it quickly turned into heavy
          front-end web development using a basic stack of HTML, CSS (SASS), and JS.
        </p>
        <h3>Strengths Incorporated</h3>
        <p>
          <ul>
            <li><b>Developer - Relationship Building</b></li>
            <li><b>Restortative - Executing</b></li>
          </ul>
        </p>
      </div>

      {/* Add more projects as needed */}
    </section>
  );
}

export default Projects;
