import React from 'react';

function Contact() {
  return (
    <section id="contact">
      <h2>Contact Me</h2>
      <p>If you're interested in my work or have any questions, feel free to reach out!</p>
      <ul>
        <li>Email: <a href="mailto:enriquellanes0421@gmail.com">enriquellanes0421@gmail.com</a></li>
        <li>LinkedIn: <a href="https://www.linkedin.com/in/enrique-llanes" target='blank'>Enrique Llanes</a></li>
        {/*<li>GitHub: <a href="https://github.com/yourusername">yourusername</a></li>*/}
        {/* Add more contact options as needed */}
      </ul>
    </section>
  );
}

export default Contact;
