import React from 'react';
import image1 from '../images/Asset1.png';

function TopImage() {
  return (
    <section id="topImage">
        <img src={image1} alt="Description" />
    </section>
  );
}

export default TopImage;
