import React from 'react';

function ProjectHeader() {
  return (
    <section id="projectHeader">
        <h2>Key Projects</h2>
    </section>
  );
}

export default ProjectHeader;
