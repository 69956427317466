import React from 'react';
import footerImage from '../images/TransMetal.png';

function Footer() {
  return (
    <footer>
        <p>
            &copy; {new Date().getFullYear()} Enrique Llanes. All Rights Reserved.
        </p>
        <img id='footerImg' src={footerImage} alt="Description"/>
    </footer>
  );
}

export default Footer;