import React from 'react';
import image1 from '../images/Enrique Llanes Headshot.jpg';
import image2 from '../images/IMG_0893.JPG';
import image3 from '../images/IMG_1059.png';
import image4 from '../images/mirror.jpg';

function About() {
  return (
    <section id="about">
        <div className="about-images" style={{ flex: 1 }}>
            {/* Assuming you have multiple images you can map through them or just put them directly */}
            <img src={image1} alt="Description" style={{ width: '100%', height: 'auto' }} />
            <img src={image2} alt="Description" style={{ width: '100%', height: 'auto' }} />
            <img src={image3} alt="Description" style={{ width: '100%', height: 'auto' }} />
            <img src={image4} alt="Description" style={{ width: '100%', height: 'auto' }} />
            {/* <img src={imageX} alt="Description" style={{ width: '100%', height: 'auto' }} />
            More images if needed */}
        </div>

        <div className="about-text">
            <h2>About Me</h2>
            <p>
                Hello! I'm Enrique, a second-year Computer Science and Computer Engineering major at UCM. 
                I'm also a Co-Designer at Bayha Group. 
                My time at Bayha Group has been fun and productive, 
                ultimately leaving me with the necessary workplace skills to thrive in any professional environment.
            </p>

            <h2>Smart Goals</h2>
            <p1>
                SMART Goals are goals that are all specific, measurable, attainable, relevant, & time-based.
                Having these criteria in mind, my three SMART Goals are:
            </p1>
            <ul>
                <li><b>1. Complete the design and development of a staff merch management tool for our team in Airtable, 
                    including merch modularity and automation features, 
                   within a three-month timeframe.</b></li>
                <li><b>2. Become proficient in excel, by accurately modelling out a full personal budget plan. </b></li>
                <li><b>3. Design and prototype three innovative, 
                        sustainability-focused merchandise items for our brand, 
                        incorporating feedback from teammates, within a six-month timeframe.</b></li>
            </ul>

            <h2>CliftonStrengths</h2>
            <p1>
                In addition to having SMART Goals, I also found my top 5 Clifton-Strenghths. These strengths not only define who I am in the workplace
                but also who I am in everything I do & accomplish. <br/>
            </p1>

            <p1><br/>My top 5 strengths are:</p1>
            <ul>
                <li><b>1. Developer - Relationship Building</b></li>
                <li><b>2. Positivity - Relationship Building</b></li>
                <li><b>3. Restorative - Executing</b></li>
                <li><b>4. Empathy - Relationship Building</b></li>
                <li><b>5. Individualization - Relationship Building</b></li>
            </ul>
        </div>
    </section>
  );
}

export default About;
